/**
 * 获取首页数据
 * */
export const getHomeData = async () => {
  const data = await asyncFetchData('/web/home/index', {
    method: 'GET'
  });
  data.value.classList = data.value.classList.map(item => {
    const levelclasslist = item.levelclasslist.map(x => {
      const kstklist = Array.isArray(x.kstklist) ? x.kstklist.map(y => {
        return {title: y.title, id: y.id, pcurl: y.pcurl, wapurl: y.wapurl}
      }) : [];
      return {name: x.name, pcurl: x.pcurl, wapurl: x.wapurl, kstklist}
    })
    return {
      name: item.name, id: item.id, pcurl: item.pcurl, wapurl: item.wapurl, levelclasslist
    };
  })
  data.value.kstklist = Array.isArray(data.value.kstklist) ?data.value.kstklist.map(item => {return {id: item.id, title: item.title, pcurl: item.pcurl, wapurl: item.wapurl}}) : [];
  data.value.wangkelist = data.value.wangkelist.map(item => {return {id: item.id, title: item.title, pcurl: item.pcurl, wapurl: item.wapurl}})
  data.value.zhidaolist = data.value.zhidaolist.map(item => {return {id: item.id, title: item.title, pcurl: item.pcurl, wapurl: item.wapurl}})
  return Promise.resolve(data);
}
/**
 * 获取试卷列表
 * @param query
 * @returns {Promise<Awaited<Ref<any>>|undefined>}
 */
export const getSjList = (query) => {
  return asyncFetchData('/web/sj/SJList', {
    method: 'GET',
    query
  })
}

/**
 * 获取试卷详情
 * @param id
 * @returns {Promise<Awaited<Ref<any>>|undefined>}
 */
export const getSjDetails = (id) => {
  return asyncFetchData('/web/sj/Index', {
    method: 'GET',
    query: {id}
  })
}

/**
 * 获取章节列表
 * @param query
 * @returns {Promise<Awaited<Ref<any>>|undefined>}
 */
export const getZjList = (query) => {
  return asyncFetchData('/web/kstkZJ/kstkzjlist', {
    method: 'GET',
    query
  })
}

/**
 * 获取章节详情
 * @param query
 * @returns {Promise<*|Awaited<*>|undefined>}
 */
export const getZjDetails = (query) => {
  return asyncFetchData('/web/kstkZJ/KstkZJDetails', {
    method: 'GET',
    query
  })
}

export const getWangkeList = (query) => {
  return asyncFetchData('/web/WangKe/WangKeList', {
    method: 'GET',
    query
  })
}

export const getQAList = (query) => {
  return asyncFetchData('/web/ZhiDao/ZhiDaoList', {
    method: 'GET',
    query
  })
}

export const getSmartList = (query) => {
  return asyncFetchData('/web/SmartHome/SmartHomeList', {
    method: 'GET',
    query
  })
}

export const getAiList = (query) => {
  return asyncFetchData('/web/KstkAI/KstkAIList', {
    method: 'GET',
    
  })
}

/**
 * 获取每日一练列表数据
 * @param query
 * @returns {Promise<*|Awaited<*>|undefined>}
 */
export const getDayList = (query) => {
  return asyncFetchData('/web/kstkday/kstkdaylist', {
    method: 'GET',
    query
  })
}

/*
* 获取每日一练详情
* @param query
* @returns {Promise<*|Awaited<*>|undefined>}
*/
export const getDayDetails = (query) => {
  return asyncFetchData('/web/kstkDay/KstkDayDetails', {
    method: 'GET',
    query
  })
}

export const getMnkc = (id) => {
  return asyncFetchData('/web/mnkc/index', {
    method: 'GET',
    query: {tid: id}
  })
}

/**
 * 获取所有搜索类型的数据
 * @param params
 * @param type
 */
export const getSearch = async (params, type) => {
  try {
    const apis = ['GetUserQueryWord', 'GetUserQueryST', 'GetUserQueryTK', 'GetUserQueryWangKe', 'GetUserQueryWenDa'];
    const data = await fetchData(`/api/UserQueryApi/${apis[type]}`, {
      method: 'GET',
      query: { ...params, stype: 1 }
    })
    
    if (data.S === '1') {
      data.UserQueryList = data.UserQueryList.map(item => {
        return {...item, colortitle: item.colortitle ?? item.title, colortmnr: item.colortmnr ?? item.tmnr,id: item.id ?? item.ID}
      })
    } else {
      data.UserQueryList = []
    }
    return Promise.resolve(data);
  } catch ( err ) {
    showError({
      statusCode: 502,
      statusMessage: err.message,
      cause: err.cause,
      fatal: true
    })
    return Promise.reject(err);
  }
}

export const getTiku = async (id) => {
  return asyncFetchData('/web/tiku/index', {
    method: 'GET',
    query:{tid: id}
  })
}

/**
 * 获取题目详情
 * @param query
 * @returns {Promise<{value}|*|* extends (object & {then(onfulfilled: infer F, ...args: infer _):
 *   any}) ? (F extends ((value: infer V, ...args: infer _) => any) ? Awaited<V> : never) : *>}
 */
export const getStDetail = async (query) => {
  try {
    const data = await asyncFetchData('/web/Kstk/StDetails', {
      method: 'GET',
      query,
      valid: true,
    });
    if (!data || !data.value) {
      throw new Error('Invalid response data');
    }
    return data;
  } catch (err) {
    showError({
      statusCode: err.statusCode ||
        .502,
      statusMessage: err.message || 'Request failed',
      cause: err.cause || 'Unknown cause',
      fatal: true,
    });
    // 确保函数在出错时也返回值，以避免未捕获的Promise错误
    return Promise.reject(err);
  }
};

/**
 * 获取试题答案
 * @param query
 * @returns {data}
 */
export const getStAnswer = (query) => {
  return fetchData('/api/ExamApi/GetQueryExamDaAn', {
    method: 'GET',
    valid: true,
    query
  })
}

/**
 * 获取微信登录二维码
 * @returns {data}
 */
export const getWxScanCode = () => {
  return fetchData('/api/UserLoginRegApi/WXQRCodeLogin', {
    method: 'GET',
    valid: true
  })
}

/**
 * 获取微信扫码登录结果
 * @param code
 * @returns {data}
 */
export const getWxScanResult = (code) => {
  return fetchData('/api/UserLoginRegApi/GetWXSceneID', {
    method: 'GET',
    query: {
      code
    }
  })
}

/**
 * 获取用户信息
 * @returns {Promise<void>}
 * @param query
 */
export const getUserInfo = async (query) => {
  const data = await fetchData('/api/UserApi/GetUserModel', {
    method: 'GET',
    query
  });
  if (data?.S !== '1') {
    await  ElMessageBox({
      title: '温馨提示',
      message: data.msg,
      showClose: false, // 隐藏右上角关闭按钮
      closeOnClickModal: false, // 禁止点击遮罩关闭
      closeOnPressEscape: false, // 禁止按下 ESC 键关闭
      distinguishCancelAndClose: true, // 区分取消和关闭（防止误触）
      confirmButtonText: '重新登录',
    });
    await logout();
  }
  if (data?.UserList[0]?.UserFace === "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132"){
    data.UserList[0].UserFace = 'https://static.ppkao.com/assets/image/avatar.png'
  }
  if (data?.UserList[0]?.RealName === '微信用户' || !data?.UserList[0]?.RealName) {
    data.UserList[0].RealName = '考试资料网用户';
  }
  return Promise.resolve(data);
}

/**
 * 获取搜题包价格列表
 * @returns {Promise<*|Awaited<*>|undefined>}
 */
export const getSearchPack = async () => {
  return fetchData('/api/SearchPackApi/GetSearchPackPrice', {
    method: 'GET',
    valid: true
  })
}

/**
 * 下单
 * @param query
  */
export const placeOrder = async (query) => {
  return fetchData('/api/PaymentApi/UserBySearchPack', {
    method: 'GET',
    valid: true,
    query
  });
}

/**
 * 根据订单ID获取订单结果
 * @param query
 * @returns {Promise<*|Awaited<*>|undefined>}
 */
export const getOrderID = async (query) => {
  return fetchData('/api/OrderApi/GetOrderByOrderID', {
    method: 'GET',
    query
  });
}

/**
 * 获取搜题包开始的时间
 * @param time
 * @returns {Promise<NodeJS.Timeout>}
 */
export const getActivityDate = async (time) => {
  const data = await fetchData('/api/HelpApi/GetActivityDate', {
    method: 'GET',
  });
  if (data.S === '1') {
    const d1 = new Date(data.EndDate.replace(/\-/g, '/'));
    const d2 = new Date(data.StatDate.replace(/\-/g, '/'));
    
    let timeDiff = d1 - d2; // 获取时间差（毫秒）
    
    const countDown = () => {
      if (timeDiff <= 0) {
        return;
      }
      
      // 计算时、分、秒、毫秒
      const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
      const seconds = Math.floor((timeDiff / 1000) % 60);
      const milliseconds = Math.floor((timeDiff % 1000) / 10);
      
      // 格式化为两位数（时、分、秒）和三位数（毫秒）
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = seconds.toString().padStart(2, '0');
      const formattedMilliseconds = milliseconds.toString().padStart(2, '0');
 
      time.value = `${formattedHours}:${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
      // 减少时间差
      timeDiff -= 10; // 每次减少 10 毫秒
    };
    
    // 设置倒计时，每 10 毫秒更新一次
    return setInterval(countDown, 10);
  }
  
}


export function getChat(query) {
  return fetchData('/api/AISearch/GetAIQueryWord', {
    method: 'GET',
    valid: true,
    query
  })
}
export function commentChat(query) {
  return fetchData('/api/AISearch/DiggExam', {
    method: 'GET',
    valid: true,
    query
  })
}

export function refreshChat(query) {
  return fetchData('/api/AISearch/GetAIQueryWordRefresh', {
    method: 'GET',
    valid: true,
    query
  })
}
export function geChatRecord(query) {
  return fetchData('/api/AISearch/GetUserQueryRecord', {
    method: 'GET',
    valid: true,
    query
  })
}

export function verificationOrder(query) {
  return fetchData('/api/InvoicedApi/GetOrderIsInvoiced', {
    method: 'GET',
    valid: true,
    query
  })
}

export function putVoice(data) {
  return fetchData('/api/InvoicedApi/UserInvoiced', {
    method: 'POST',
    valid: true,
    body: data
  })
}
