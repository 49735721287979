import { Base64 } from 'js-base64'
import md5 from 'js-md5';

const generateOptions = (options) => {
  const headers = useRequestHeaders(['cookie'])
  const runConfig = useRuntimeConfig(); // 获取环境变量
  const store = useUserStore();
  const { isLogin, UserID, UserToken } = store.userInfo;
  return {
    baseURL: runConfig.public.apiBase,
    timeout: 20 *1000,
    headers,
    ...options,
    onRequest({ request, options }) {
      // 清除params中值为空的属性
      if (options.query) {
        options.query = Object.keys(options.query).reduce((obj, key) => {
          // 检查属性值是否非空，包括0
          if (options.query[key] !== undefined && options.query[key] !== null && options.query[key] !== '') {
            obj[key] = options.query[key];
          }
          return obj;
        }, {});
      }
      if (/^\/api/.test(request)) {
        options.query = options.query ?? {};
       
        let params = { ...(options.query ?? options.bdoy ?? {}), source: options?.query?.source ?? options?.body?.source ?? 'nuxt-web' };
        if (isLogin) { // 如果已登录
          params = { ...params, UserID, UserToken };// 添加用户信息
        }
 
        params = { ...params, sign: getSign(params)};
        if (options.method.toLocaleUpperCase() === 'GET') {
          options.query && Object.assign(options.query, params); // 添加get数据签名
        } else if (options.method.toLocaleUpperCase() === 'POST') {
          options.body && Object.assign(options.body, params); // 添加post等数据签名
        }
      }
    },
    onResponse({ request, response, options }) {
      // Log response
      if (/\/api/.test(request)) {
        response._data = decode(response._data)
      }
      
    }
  }
}
const getSign = data => {
  try {
    if (data) {
      const res = [];
      for (const i in data) {
        if (( data[i] || data[i] === 0 ) && i !== 'sign' && i !== 'undefined' && i !== 'null') {
          res.push(`${ i }=${ decodeURIComponent(data[i]) }`);
        }
      }
      res.sort();
      const result = res.join(''); //排序后拼接成字符串
      return md5(result);
    }
    return md5(''); //没有任何参数空解码
  } catch ( err ) {
    console.error(`@csqz/common Error: \n ${ err }`);
  }
};
const _decode = str => Base64.decode(str.toString());
const decode = obj => {
  const res = {};
  Object.keys(obj).forEach(key => {
    const val = obj[key];
    if (Array.isArray(val)) {
      res[key] = [];
      val.forEach(item => {
        res[key].push(decode(item));
      });
    } else if (val instanceof Object) {
      res[key] = decode(val);
    } else {
      res[key] = _decode(val);
    }
  });
  return res;
};
/**
 * 适合需要在组件中直接使用 API 数据的场景。
 * 当你只需要简单的数据获取，并且不需要缓存或复杂的状态管理时，可以使用 useFetch。
 * @description 同步请求封装
 * @param {String} url  请求地址，不包含请求域名，如：/api/test
 * @param {Object} options  请求参数
 * @returns data or error
 */
export const fetchData = async (url,  options) => {
  return new Promise((resolve, reject) => {
    $fetch(url, generateOptions(options)).then(data => {
      if (options.valid && data.S === '0') {
        ElMessage.error(data.msg);
        reject(data)
      }
      resolve(data)
    }).catch(error => {
      if (/timeout/ig.test(error?.message)) {
        ElMessage.error('请求超时, 请刷新重试。');
      } else {
        ElMessage.error(error.message);
      }
      reject(error);
    });
  })
};

/**
 * 适合 SSR 场景，可以缓存数据。
 * 更加复杂和灵活，适合在页面加载时获取数据，并在组件之间共享数据。
 * @description 异步请求封装
 * @param {String} url  请求地址，不包含请求域名，如：/api/test
 * @param {Object} options  请求参数
 */
export const asyncFetchData = async (url,  options) => {
  const event = useRequestEvent()
  const key = url.split('/').pop() //这里取接口最后的名称做key
  options = generateOptions(options);
  const result = await useFetch(url, { ...options, key });
  const { data, error, status} = result;

  if (status === 'success' || status.value === 'success') {
    if (data.value.code === 500) {
      data.value = {
        seotitle: '考试资料网_考试试题_考试题库_找答案就上考试资料网',
        description: '考试资料网目前已为广大考友提供10万套在线试卷进行全真测试、模拟练习，同时提供公务员，外语类，财会类，建筑类，职业资格，学历考试，医药类，外贸类，计算机类等考试的报名时间、考试时间、考试大纲、成绩查询等相关资讯以及历年真题、模拟试题、名师视频辅导课程等备考资料；是一个集资料下载与在线考试系统、资讯发布于一体的学习资料网站。',
        keywords: '考试试题,执业医师,考试题库,一级建造师,二级建造师,在线考试,执业药师,经济师',
        ...data.value
      }
    }
    return Promise.resolve(data);
  }
  const err = error._object[error._key];
  throw createError({
    statusCode: err.statusCode,
    statusMessage: err.message,
    fatal: true,
    cause: err.cause,
    data: {
      requestInfo: {...options, url:  options.baseURL+url, data, err}
    }
  })
}



