
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as ailPHq4SizwGMeta } from "D:/code/ppkao.com/ppkao/pages/ai.vue?macro=true";
import { default as downloadk3Vw5LoJagMeta } from "D:/code/ppkao.com/ppkao/pages/download.vue?macro=true";
import { default as _91id_93MkJu5Dk0e1Meta } from "D:/code/ppkao.com/ppkao/pages/exam/[id].vue?macro=true";
import { default as indexNcckLWlc8fMeta } from "D:/code/ppkao.com/ppkao/pages/index.vue?macro=true";
import { default as invoiceeKDgcW7MLaMeta } from "D:/code/ppkao.com/ppkao/pages/invoice.vue?macro=true";
import { default as login0nB2SNpin5Meta } from "D:/code/ppkao.com/ppkao/pages/login.vue?macro=true";
import { default as index6wWIy7fnmuMeta } from "D:/code/ppkao.com/ppkao/pages/mnkc/index.vue?macro=true";
import { default as _91id_93_46htmcoWoxRnMcIMeta } from "D:/code/ppkao.com/ppkao/pages/sj/[id].htm.vue?macro=true";
import { default as sosO1NAtMwAMMeta } from "D:/code/ppkao.com/ppkao/pages/so.vue?macro=true";
import { default as _91id_93SCXr8Qdbw6Meta } from "D:/code/ppkao.com/ppkao/pages/tiku/[id].vue?macro=true";
import { default as indexUmyHHDPC44Meta } from "D:/code/ppkao.com/ppkao/pages/tiku/index.vue?macro=true";
import { default as accountyJKsUy6JRgMeta } from "D:/code/ppkao.com/ppkao/pages/user/account.vue?macro=true";
import { default as couponpx6HJMO9tvMeta } from "D:/code/ppkao.com/ppkao/pages/user/coupon.vue?macro=true";
import { default as courselJiFMf9oxcMeta } from "D:/code/ppkao.com/ppkao/pages/user/course.vue?macro=true";
import { default as index93t7aVYyZxMeta } from "D:/code/ppkao.com/ppkao/pages/user/index.vue?macro=true";
import { default as orderJFt8QAHipeMeta } from "D:/code/ppkao.com/ppkao/pages/user/order.vue?macro=true";
import { default as packetuoI8esAus7Meta } from "D:/code/ppkao.com/ppkao/pages/user/packet.vue?macro=true";
import { default as tiku5kAN5teHdtMeta } from "D:/code/ppkao.com/ppkao/pages/user/tiku.vue?macro=true";
import { default as _91id_93nG6fb31ynLMeta } from "~/pages/tiku/[id].vue?macro=true";
import { default as ListWagoWBNlDCMeta } from "~/components/tiku/List.vue?macro=true";
import { default as TopicListj0YAp1xEWuMeta } from "~/components/TopicList.vue?macro=true";
export default [
  {
    name: "ai",
    path: "/ai",
    meta: ailPHq4SizwGMeta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/ai.vue")
  },
  {
    name: "download",
    path: "/download",
    meta: downloadk3Vw5LoJagMeta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/download.vue")
  },
  {
    name: "exam-id",
    path: "/exam/:id()",
    component: () => import("D:/code/ppkao.com/ppkao/pages/exam/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("D:/code/ppkao.com/ppkao/pages/index.vue")
  },
  {
    name: "invoice",
    path: "/invoice",
    meta: invoiceeKDgcW7MLaMeta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/invoice.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login0nB2SNpin5Meta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/login.vue")
  },
  {
    name: "mnkc",
    path: "/mnkc",
    component: () => import("D:/code/ppkao.com/ppkao/pages/mnkc/index.vue")
  },
  {
    name: "sj-id.htm",
    path: "/sj/:id().htm",
    component: () => import("D:/code/ppkao.com/ppkao/pages/sj/[id].htm.vue")
  },
  {
    name: "so",
    path: "/so",
    component: () => import("D:/code/ppkao.com/ppkao/pages/so.vue")
  },
  {
    name: "tiku-id",
    path: "/tiku/:id()",
    component: () => import("D:/code/ppkao.com/ppkao/pages/tiku/[id].vue")
  },
  {
    name: "tiku",
    path: "/tiku",
    component: () => import("D:/code/ppkao.com/ppkao/pages/tiku/index.vue")
  },
  {
    name: "user-account",
    path: "/user/account",
    meta: accountyJKsUy6JRgMeta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/user/account.vue")
  },
  {
    name: "user-coupon",
    path: "/user/coupon",
    meta: couponpx6HJMO9tvMeta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/user/coupon.vue")
  },
  {
    name: "user-course",
    path: "/user/course",
    meta: courselJiFMf9oxcMeta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/user/course.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: index93t7aVYyZxMeta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/user/index.vue")
  },
  {
    name: "user-order",
    path: "/user/order",
    meta: orderJFt8QAHipeMeta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/user/order.vue")
  },
  {
    name: "user-packet",
    path: "/user/packet",
    meta: packetuoI8esAus7Meta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/user/packet.vue")
  },
  {
    name: "user-tiku",
    path: "/user/tiku",
    meta: tiku5kAN5teHdtMeta || {},
    component: () => import("D:/code/ppkao.com/ppkao/pages/user/tiku.vue")
  },
  {
    name: "day",
    path: "/day/:id(\\d+):html(.html)",
    component: () => import("~/pages/tiku/[id].vue")
  },
  {
    name: "day_id",
    path: "/day/:id(\\d+)/:empty(\\d+)",
    component: () => import("~/pages/tiku/[id].vue")
  },
  {
    name: "tiku_type_list",
    path: "/:type(tiku|mnkc)/:id(\\d+)",
    component: () => import("~/pages/tiku/[id].vue")
  },
  {
    name: "mip_tiku_list",
    path: "/mip/:type(tiku|mnkc)/:id(\\d+)",
    component: () => import("~/pages/tiku/[id].vue")
  },
  {
    name: "zhangjie_list",
    path: "/zhangjie/:id(\\d+)",
    meta: {"name":"chapter"},
    component: () => import("~/components/tiku/List.vue")
  },
  {
    name: "sj_id_list",
    path: "/sjlb/:id(\\d+)/:page(\\d+)?",
    meta: {"name":"paper"},
    component: () => import("~/components/tiku/List.vue")
  },
  {
    name: "sj_type_list",
    path: "/sjlb/:type(zhenti|moniti|yati)/:id(\\d+)/:page(\\d+)?",
    meta: {"name":"paper"},
    component: () => import("~/components/tiku/List.vue")
  },
  {
    name: "day_all",
    path: "/tiku/:id(\\d+)/day",
    meta: {"name":"day"},
    component: () => import("~/components/tiku/List.vue")
  },
  {
    name: "day_date",
    path: "/tiku/:id(\\d+)/:date(\\d{8})",
    meta: {"name":"day"},
    component: () => import("~/components/tiku/List.vue")
  },
  {
    name: "tiku_shiti_id",
    path: "/tiku/shiti/:id(\\d+|[a-fA-F0-9]{32}):html(.html)?",
    meta: {"type":"answer","stfrom":1,"title":"推荐试题"},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "tiku_shiti_id_order",
    path: "/tiku/shiti/:id(\\d+|[a-fA-F0-9]{32})_:order(\\d+):html(.html)?",
    meta: {"type":"answer","stfrom":1},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "tiku_shiti_answer",
    path: "/tiku/daan/:id(\\d+)/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":1},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "mip_tiku_shiti_id",
    path: "/:type(mip|3g)/tiku/shiti/:id(\\d+|[a-fA-F0-9]{32}):html(.html)?",
    meta: {"type":"answer","stfrom":1,"title":"推荐试题"},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "type_tiku_shiti_id_order",
    path: "/:type(mip|3g)/tiku/shiti/:id(\\d+|[a-fA-F0-9]{32})_:order(\\d+)",
    meta: {"type":"answer","stfrom":1},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "type_tiku_shiti_answer",
    path: "/:type(mip|3g)/tiku/daan/:id(\\d+)/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":1},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "kaoti_id",
    path: "/kaoti/:id(\\d+|[a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":2},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "kaoti_answer",
    path: "/kaoti/daan/:id(\\d+)/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":2},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "mip_kaoti_id",
    path: "/mip/kaoti/:id(\\d+|[a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":2},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "mip_kaoti_answer",
    path: "/mip/kaoti/daan/:id(\\d+)/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":2},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "shiti_id",
    path: "/shiti/:id(\\d+|[a-fA-F0-9]{32})",
    meta: {"title":"试卷试题","type":"answer","stfrom":0},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "shiti_answer",
    path: "/shiti/:id(\\d+)/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":0},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "mip_shiti_id",
    path: "/mip/shiti/:id(\\d+|[a-fA-F0-9]{32})",
    meta: {"title":"试卷试题","type":"answer","stfrom":0},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "mip_shiti_answer",
    path: "/mip/shiti/:id(\\d+)/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":0},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "type_shiti_id",
    path: "/:type(mip|3g)/shiti/:id(\\d+|[a-fA-F0-9]{32})",
    meta: {"title":"试卷试题","type":"answer","stfrom":0},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "type_daan_id_guid",
    path: "/:type(mip|3g)/daan/:id(\\d+)/:guid([a-fA-F0-9]+)",
    meta: {"title":"试卷试题","type":"answer","stfrom":0},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "daan_id_guid",
    path: "/daan/:id(\\d+)/:guid([a-fA-F0-9]+)",
    meta: {"title":"试卷试题","type":"answer","stfrom":0},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "smart_home_list",
    path: "/SmartHome/:page(\\d+)?",
    meta: {"title":"智能家居","type":"list"},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "smart_home_answer",
    path: "/SmartHome/daan/:id([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":7},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "smart_home_answer_guid",
    path: "/SmartHome/daan/:id([a-fA-F0-9]{32})/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":7},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "zhidao_list",
    path: "/zhidao/:page(\\d+)?",
    meta: {"title":"问&答","type":"list"},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "zhidao_answer",
    path: "/zhidao/daan/:id([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":5},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "zhidao_answer_guid",
    path: "/zhidao/daan/:id([a-fA-F0-9]{32})/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":5},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "wangke_list",
    path: "/wangke/:page(\\d+)?",
    meta: {"title":"网课题目","type":"list"},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "wangke_answer",
    path: "/wangke/daan/:id([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":4},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "wangke_answer_guid",
    path: "/wangke/daan/:id([a-fA-F0-9]{32})/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":4},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "mip_wangke_list",
    path: "/mip/wangke/:page(\\d+)?",
    meta: {"title":"网课题目","type":"list"},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "mip_wangke_answer",
    path: "/mip/wangke/daan/:id([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":4},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "mip_wangke_answer_guid",
    path: "/mip/wangke/daan/:id([a-fA-F0-9]{32})/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":4},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "kstkai_list",
    path: "/kstkai/:page(\\d+)?",
    meta: {"title":"热门试题","type":"list"},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "kstkai_answer",
    path: "/kstkai/daan/:id([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":6},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "kstkai_answer_guid",
    path: "/kstkai/daan/:id([a-fA-F0-9]{32})/:guid([a-fA-F0-9]{32})",
    meta: {"type":"answer","stfrom":6},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "chapter_list",
    path: "/zhangjie/:date(\\d{6})/:id(\\d+):html(.html)",
    meta: {"title":"章节练习","type":"list"},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "mip_chapter_list",
    path: "/mip/zhangjie/:date(\\d{6})/:id(\\d+):html(.html)",
    meta: {"title":"章节练习","type":"list"},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "day_list",
    path: "/tiku/:date(\\d{6})/:id(\\d+):html(.html)",
    meta: {"title":"每日一练","type":"list"},
    component: () => import("~/components/TopicList.vue")
  },
  {
    name: "mip_day_list",
    path: "/mip/tiku/:date(\\d{6})/:id(\\d+):html(.html)",
    meta: {"title":"每日一练","type":"list"},
    component: () => import("~/components/TopicList.vue")
  }
]