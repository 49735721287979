/**
 * 向iframe发送数据
 * @param data 需要发送的数据
 * @param iframeEle 需要接收的iframe的元素id
 */
export const sendMessageIframe = (data, iframeEle = 'iframe-box') => {
  const iframe = document.getElementById(iframeEle);
  if (iframe) {
    const iframeOrigin = new URL(iframe.src).origin;
    iframe.contentWindow.postMessage(data, iframeOrigin);
  }
}
export const throttle = (fn, wait) => {
  let lastCall = 0;
  
  return function(...args) {
    const now = Date.now();
    if (now - lastCall >= wait) {
      lastCall = now;
      fn(...args);
    }
  };
}
// 题目类型
export const getTopicType = (type) => {
  if (!type) return '问答题';
  if (isNaN(type)) return type;
  const EXAM_TYPES = {
    1: '单项选择题',
    2: '多项选择题',
    3: '不定项选择题',
    4: '判断题',
    5: '报关编码题',
    6: '填空题',
    7: '简答题',
    8: '论述题',
    9: '组合套题',
    10: '比较题',
    11: '比较题',
    12: '配伍题',
    13: '名词解析题',
    18: '问答题'
  };
  return EXAM_TYPES[type];
};
/**
 * 解析QZ_KSUser这个token
 * @param userToken
 * @returns {{}}
 */
export function parseUserToken(info) {
  const reg = /([^\?&=]+)=([^&]+)/g;
  const result = {};
  let match;
  while (( match = reg.exec(info) )) {
    const key = match[1];
    result[key] = decodeURIComponent(match[2]);
  }
  return result;
}
export function setCookie(name = "QZ_KSUser", value = null) {
  const Cookie = useCookie(name, { domain: '.ppkao.com', sameSite: 'None', secure: true});
  Cookie.value = value;
  return Promise.resolve();
}
export function getCookie(name = "QZ_KSUser") {
  const ppkaoCookie = useCookie(name, { domain: 'ppkao.com', sameSite: 'None', secure: true});
  const dotCookie = useCookie(name, { domain: '.ppkao.com', sameSite: 'None', secure: true});
  const wwwCookie = useCookie(name, { domain: 'www.ppkao.com', sameSite: 'None', secure: true});
  const mCookie = useCookie(name, { domain: 'm.ppkao.com', sameSite: 'None', secure: true});
  const h5Cookie = useCookie(name, { domain: 'h5.ppkao.com', sameSite: 'None', secure: true});
  const newapiCookie = useCookie(name, { domain: 'h5.ppkao.com', sameSite: 'None', secure: true});
  const cookies = [ppkaoCookie, dotCookie, wwwCookie, mCookie, h5Cookie, newapiCookie];
  const cookieValue = cookies.find(cookie => cookie.value !== undefined && cookie.value !== null);
  
  // 如果有值则返回，否则返回一个默认值或者处理为 undefined/null
  return cookieValue ? cookieValue.value : null;
}
export function delCookie(name) {
  const cookie = useCookie(name, { sameSite: 'None', secure: true});
  cookie.value = null;
}
export function logout(to = true) {
  const store = useUserStore();
  const router = useRouter();
  const cookie = useCookie("QZ_KSUser", { sameSite: 'None', secure: true});
  const Cookie = useCookie("QZ_KSUser", { domain: 'h5.ppkao.com', sameSite: 'None', secure: true});
  const Cookie1 = useCookie("QZ_KSUser", { domain: 'www.ppkao.com', sameSite: 'None', secure: true});
  const Cookie2 = useCookie("QZ_KSUser", { domain: 'newapi.ppkao.com', sameSite: 'None', secure: true});
  const Cookie3 = useCookie("QZ_KSUser", { domain: 'm.ppkao.com', sameSite: 'None', secure: true});
  const Cookie4 = useCookie("QZ_KSUser", { domain: '.ppkao.com', sameSite: 'None', secure: true});
  cookie.value = null;
  Cookie.value = null;
  Cookie1.value = null;
  Cookie2.value = null;
  Cookie3.value = null;
  Cookie4.value = null;
  setCookie("QZ_KSUser");
  store.logout();
  if (to) {
    router.push('/login');
  }
}
export function linkTo(item, prefix = false) {
 if (!item) return '/';
  const nuxtApp = useNuxtApp();
  let url = item;
  if (typeof item === 'string') {
    // 检查 URL 是否包含协议 (http:// 或 https://)
    const hasDomain = /^https?:\/\/\S+/.test(item);
    
    // 如果没有域名（没有 http:// 或 https://），则添加域名
    if (!hasDomain) {
      url = `https://${nuxtApp.$device.isDesktop ? 'www' : 'm'}.ppkao.com${item}`;
    }
  }
  if (item.urlPC || item.urlApp) url = nuxtApp.$device.isDesktop ? item.urlPC : item.urlApp;
  else if (item.pcurl || item.wapurl) url =  nuxtApp.$device.isDesktop ? item.pcurl : item.wapurl;
  if (nuxtApp.$device.isCrawler) return url;
  if (typeof url === 'string') {
    return prefix ? url : url.replace(/^https?:\/\/(.*).ppkao.com/, '');
  }
  return '/';
}
export function protectMath(content) {
  return content
    .replace(/\\\[(.+?)\\\]/g, (match, p1) => `%%DISPLAYMATH%%${p1}%%DISPLAYMATH%%`)
    .replace(/\\\((.+?)\\\)/g, (match, p1) => `%%INLINE_MATH%%${p1}%%INLINE_MATH%%`)
    .replace(/\$\$([^$]+)\$\$/g, (match, p1) => `%%DISPLAYMATH%%${p1}%%DISPLAYMATH%%`)
    .replace(/\$([^$]+)\$/g, (match, p1) => `%%INLINE_MATH%%${p1}%%INLINE_MATH%%`);
}
export function restoreMath(content) {
  return content
    .replace(/%%DISPLAYMATH%%(.+?)%%DISPLAYMATH%%/g, (match, p1) => `\\[${p1}\\]`)
    .replace(/%%INLINE_MATH%%(.+?)%%INLINE_MATH%%/g, (match, p1) => `\\(${p1}\\)`);
}
export function categorizeData(data) {
  const today = new Date();
  const categorized = {};
  
  data.forEach(item => {
    const itemDate = new Date(item.AddDate);
    const timeDiff = today.getTime() - itemDate.getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
    
    let category = '';
    if (daysDiff === 0) {
      category = '今天';
    } else if (daysDiff === 1) {
      category = '昨天';
    } else if (daysDiff <= 7) {
      category = '七天前';
    } else if (daysDiff <= 30) {
      category = '一个月前';
    }
    
    if (category) {
      if (!categorized[category]) {
        categorized[category] = [];
      }
      categorized[category].push(item);
    }
  });
  
  // 将对象转换为数组格式，便于在 Vue 模板中遍历
  return Object.keys(categorized).map(key => ({
    day: key,
    data: categorized[key]
  }));
}
export const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    let x = +a[key];
    let y = +b[key];
    return x > y ? -1 : x < y ? 0 : 1;
  });
};

