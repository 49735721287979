import { getUserInfo } from '~/api';
import { getUserSubject } from '~/api/exam';

export const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: {
      UserFace: 'https://static.ppkao.com/assets/image/avatar.png',
      RealName: '考试资料网用户',
      isLogin: false
    },
    OrderModel: [],
    userSubjectInfo: [],
    couponList: []
  }),
  actions: {
 
    // 设置用户信息
    setUserInfo(data) {
      const { UserID, UserToken, UserName, UserFace } = data;
      setCookie('QZ_KSUser', `UserID=${UserID}&UserName=${UserName}&UserToken=${UserToken}&UserFace=${UserFace}`);
      console.log('[设置登录状态]:', data)
      this.userInfo = { ...data, isLogin: true }
    },
    logout() {
      this.userInfo = {};
    },
    // 设置订单模型
    setOrderModel(data) {
      this.OrderModel = data
    },
    // 设置用户学科信息
    setUserSubjectInfo(data) {
      this.userSubjectInfo = data
    },
    setCouponList(data) {
      this.couponList = data;
    },
    // 获取用户信息（异步）
    async getUserInfo() {
      try {
        const { UserList } = await getUserInfo()
        this.setUserInfo(UserList[0])
      } catch (error) {
        console.error(error)
      }
    },
    async getUserBuySubject() {
      try {
        const { UserSubjectList } = await getUserSubject();
        this.setUserSubjectInfo(UserSubjectList)
      } catch (error) {
        console.error(error)
      }
    },
  },
})
