/**
 * @description: 用户获取做题结果
 */
export function getTopicResultList(query) {
  return fetchData('/api/ExamApi/GetUserRecordList', {
    method: 'get',
    query,
  });
}

/**
 * @description: 获取用户当前科目下所有的模拟试卷
 */
export function getSimulationPaper(query) {
  return fetchData('/api/TestPaperApi/GetZhentiSimulation', {
    method: 'get',
    query,
  });
}

/**
 * @description: 用户做试卷保存答案接口
 */
export function savePaperAnswer(query) {
  return fetchData('/api/TestPaperApi/SaveAnswer', {
    method: 'get',
    query,
  });
}

/**
 * @description: 用户做试卷保存答案接口
 */
export function saveAnswer(query) {
  return fetchData('/api/ExamApi/SaveAnswer', {
    method: 'get',
    query,
  });
}

/**
 * @description: 获取用户购买了的题库
 */
export async function getUserSubject(query) {
  const data = await fetchData('/api/UserSubjectApi/GetUserSubject', {
    method: 'get',
    query,
  });
  if (data.S === "1") {
    data.UserSubjectList = data.UserSubjectList.filter(
      (item) =>
        item.IsPayment === "True" && Date.parse(item.EndDate) > new Date().getTime()
    );
  }
  return Promise.resolve(data);
}

/**
 * @description: 获取题库下面章节
 */
export function getChapter(query) {
  return fetchData('/api/KstkZJApi/GetKstkZJByID', {
    method: 'get',
    query,
    valid: true
  });
}

/**
 * @description: 获取用户下面的试题收藏题目
 */
export function getCollectList(query) {
  return fetchData('/api/FavoriteApi/GetUserSTFavoriteType', {
    method: 'get',
    query,
  });
}

/**
 * @description: 获取用户的错题记录列表
 */
export function getErrorList(query) {
  return fetchData('/api/UserSTRecordApi/GetUserErrorST', {
    method: 'get',
    query,
  });
}

/**
 * @description: 题目纠错接口
 */
export function correctError(query) {
  return fetchData('/api/ExamApi/examError', {
    method: 'get',
    query,
  });
}

/**
 * @description: 做题取题目id List
 */
export function getExamId(query) {
  return fetchData('/api/ExamApi/getNextExamId', {
    method: 'get',
    query,
    valid: true,
  });
}

/**
 * @description: 章下面没有节的时候直接获取章的信息
 */
export function getChapterTopicInfo(query) {
  return fetchData('/api/KSTKClassApi/GetKSTKClassModelByID', {
    method: 'get',
    query,
  });
}

/**
 * @description: 获取题目的数量和类型
 */
export function getTopicTypeAndNum(query) {
  return fetchData('/api/KstkApi/GetKstkTypeNumByTid', {
    method: 'get',
    query,
  });
}

/**
 * @description: 获取题目数组用于渲染做题
 */
export function getExamListById(query) {
  return fetchData('/api/ExamApi/getExamCon', {
    method: 'get',
    query,
  });
}

/**
 * @description: 获取"节"列表
 */
export function getNodeList(query) {
  return fetchData('/api/node/list', {
    method: 'get',
    query,
  });
}

/**
 * @description: 修改账号密码的前置请求更新令牌
 */
export function changePwd(query) {
  return fetchData('/api/ppkao/change/pwd', {
    method: 'get',
    query,
  });
}

/**
 * @description: ppkao 修改密码接口
 */
export function changePassword(query) {
  return fetchData('/api/UserApi/UpdatePwd', {
    method: 'get',
    query,
  });
}

// 这一块是试卷相关的接口 Start

/**
 * @description: 获取试卷---试题答题卡接口
 */
export function getExamCardList(query) {
  return fetchData('/api/TestPaperApi/GetExamCard', {
    method: 'get',
    query,
  });
}

/**
 * @description: 获取试卷---试题数据接口
 */
export function getExamTopicList(query) {
  return fetchData('/api/TestPaperApi/ViewAnswer', {
    method: 'get',
    query,
  });
}

/**
 * @description: 提交试卷做题数据，当题目做完点击提交试卷的时候触发
 */
export function getPaperResult(query) {
  return fetchData('/api/TestPaperApi/GetUserRecordNum', {
    method: 'get',
    query,
  });
}

/**
 * @description: 试卷的收藏功能接口
 */
export function paperCollect(query) {
  return fetchData('/api/TestPaperApi/AddFavorites', {
    method: 'get',
    query,
  });
}

/**
 * @description: 模拟考场随机获取题目数量和题目类型
 */
export function bannerGetTopic(query) {
  return fetchData('/api/kstkapi/GetKstkSJRndZJ', {
    method: 'get',
    query,
  });
}

// 这一块是试卷相关的接口 End

/**
 * @description: 收藏题目做题
 */
export function getCollectData(query) {
  return fetchData('/api/ExamApi/GetExamBySTIDs', {
    method: 'get',
    query,
  });
}

/**
 * @description: 收藏题目
 */
export function topicCollect(query) {
  return fetchData('/api/ExamApi/favExamType', {
    method: 'get',
    query,
  });
}

// 这里下面都是学习计划用到的相关接口

/**
 * @description: 获取章节数量
 */
export function GetChapterNumApi(query) {
  return fetchData('/api/KstkZJApi/GetChapterNum', {
    method: 'get',
    query,
  });
}
