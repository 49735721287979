import payload_plugin_awyBkvyjYq from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_zkZh400PRd from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pghgwujolj5htfrbswqnqvo42e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xsBN7HV4F2 from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pghgwujolj5htfrbswqnqvo42e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BE9XzDNaVn from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pghgwujolj5htfrbswqnqvo42e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_tTSHa97TAc from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pghgwujolj5htfrbswqnqvo42e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_J1VVeaSPSy from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pghgwujolj5htfrbswqnqvo42e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_fKPm18b6YX from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pghgwujolj5htfrbswqnqvo42e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_y3Pj5YxJ7v from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pghgwujolj5htfrbswqnqvo42e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Vpp2568g7T from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "D:/code/ppkao.com/ppkao/.nuxt/components.plugin.mjs";
import prefetch_client_vL2fm1oJQU from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pghgwujolj5htfrbswqnqvo42e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_FVKnXwwGF2 from "D:/code/ppkao.com/ppkao/node_modules/.pnpm/v-wave@2.0.0_magicast@0.3.5_rollup@4.29.1_vue@3.5.13_typescript@5.7.2_/node_modules/v-wave/nuxt/runtime/plugin.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "D:/code/ppkao.com/ppkao/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "D:/code/ppkao.com/ppkao/.nuxt/element-plus-injection.plugin.mjs";
import customFlag_ArlpHMFvnk from "D:/code/ppkao.com/ppkao/plugins/customFlag.js";
import darkMode_client_Xl1WfE0UPk from "D:/code/ppkao.com/ppkao/plugins/darkMode.client.js";
import errorHandler_cfkZ75xwSQ from "D:/code/ppkao.com/ppkao/plugins/errorHandler.js";
import vHtmlImage_kMvnfqEPXb from "D:/code/ppkao.com/ppkao/plugins/vHtmlImage.js";
export default [
  payload_plugin_awyBkvyjYq,
  revive_payload_client_zkZh400PRd,
  unhead_xsBN7HV4F2,
  router_BE9XzDNaVn,
  payload_client_tTSHa97TAc,
  navigation_repaint_client_J1VVeaSPSy,
  check_outdated_build_client_fKPm18b6YX,
  chunk_reload_client_y3Pj5YxJ7v,
  plugin_vue3_Vpp2568g7T,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vL2fm1oJQU,
  plugin_wy0B721ODc,
  plugin_FVKnXwwGF2,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  customFlag_ArlpHMFvnk,
  darkMode_client_Xl1WfE0UPk,
  errorHandler_cfkZ75xwSQ,
  vHtmlImage_kMvnfqEPXb
]