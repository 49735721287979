<template>
  <div class="bg">
    <NuxtLoadingIndicator color="#0479FF"   />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
  <ClientOnly fallback-tag="div">
    <template v-if="isDesktop">
      <LazyWxScan :show="modalStore.scan" @success="onSuccess('scan')"></LazyWxScan>
      <LazyPay :show="modalStore.pay" @success="onSuccess('pay')"></LazyPay>
    </template>
<!--    <template v-else>-->
<!--      <LazyMobilePage :show="modalStore.scan || modalStore.pay" ></LazyMobilePage>-->
<!--    </template>-->
  </ClientOnly>
</template>
<script setup>
const { isDesktop } = useDevice();
const modalStore = useModalStore();

const nuxtApp = useNuxtApp();

const effect = computed(() => nuxtApp.$darkMode?.isDarkMode ? 'dark' : 'light');
const onSuccess = (type) => {
  modalStore.onSuccess();
}
if (process.client) {
  watchEffect(() => {
    document.body.classList.toggle('dark', nuxtApp.$darkMode?.isDarkMode.value);
  });

}
</script>
