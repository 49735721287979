// plugins/vHtmlImage.js
import { defineNuxtPlugin } from '#app'
import { ref, h, createApp, defineAsyncComponent } from 'vue'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('html-image', {
    beforeMount(el) {
      const imgs = el.querySelectorAll('img')
      imgs.forEach(img => {
        const src = img.getAttribute('src')
        if (src) {
          img.addEventListener('click', async (event) => {
            event.stopPropagation()
            event.preventDefault()
            
            // 动态导入 ImagePreview 组件
            const ImagePreview = defineAsyncComponent(() =>
              import('@/components/ImagePreview.vue')
            )
            
            const previewVisible = ref(true)
            const closePreview = () => {
              previewVisible.value = false
            }
            
            // 动态创建预览组件
            const previewComponent = h(ImagePreview, {
              imageSrc: src,
              onClose: closePreview
            })
            
            const container = document.createElement('div')
            document.body.appendChild(container)
            
            const app = createApp(previewComponent)
            app.mount(container)
            
            // 当预览组件关闭时，移除它
            watch(previewVisible, (newVal) => {
              if (!newVal) {
                app.unmount()
                container.remove()
              }
            })
          })
        }
      })
    }
  })
})
