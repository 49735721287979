export default defineNuxtRouteMiddleware((to) => {
  const device = useDevice();  // 获取设备信息
  const isMobile = device.isMobileOrTablet;
  
  if (process.server) {
    const headers = useRequestHeaders(['host']);  // 获取请求头中的主机名
    const hostname = headers.host || '';
    
    if (isMobile && hostname === 'www.ppkao.com') {
      // 如果是移动端访问旧域名，跳转到新域名
      const redirectUrl = `https://m.ppkao.com${to.fullPath}`;
      return navigateTo(redirectUrl, { redirectCode: 301, external: true });
    }
    
    if (!isMobile && hostname === 'm.ppkao.com') {
      // 如果是桌面端访问旧域名，跳转到新域名
      const redirectUrl = `https://www.ppkao.com${to.fullPath}`;
      return navigateTo(redirectUrl, { redirectCode: 301, external: true });
    }
  }
});
