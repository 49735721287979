// plugins/custom-flag.js
export default defineNuxtPlugin (({ $device }) => {
  // 获取底层请求事件
  const { host, 'user-agent': userAgent } = useRequestHeaders(['host', 'user-agent']);
  
  const isMobileDomain = host === 'm.ppkao.com';
  const isDesktopDomain = host === 'www.ppkao.com';
  
  // 判断是否为爬虫

  if ($device.isCrawler) {
    // 爬虫优先根据域名判断设备
    if (isMobileDomain) {
      $device.isMobileOrTablet = true;
      $device.isMobile = true;
      $device.isDesktop = false;
      $device.isDesktopOrTablet = false;
    } else if (isDesktopDomain) {
      $device.isDesktop = true;
      $device.isMobileOrTablet = false;
      $device.isMobile = false;
    }
  }
}
)
