
export const useModalStore = defineStore('modal', {
  state: () => ({
    pay: false,
    scan: false,
    onPurchaseSuccess: null,
  }),
  actions: {
    setModal(type, value, onPurchaseSuccess) {
      const { isMobile, isIos } = useDevice();
      if (value && isMobile) {
        location.href = `https://m.ppkao.com/user/login/index_upvip.html#/?redirect_url=${location.href}`;
        localStorage.setItem('to_pay_result', 1);
      }
      if (type === 'pay') {
      
        this.pay = value  // 使用 this 来访问 state
      } else if (type === 'scan') {
        this.scan = value
      }
      if (value) {
        this.onPurchaseSuccess = onPurchaseSuccess;
      }
    },
    onSuccess() {
      if (this.onPurchaseSuccess) {
        this.onPurchaseSuccess();
        this.onPurchaseSuccess = null;
      }
    },
  },
})
