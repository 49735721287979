<!-- layouts/error.vue -->
<template>
  <div class="error-mask bg">
    <NuxtLayout name="header"></NuxtLayout>
    <div class="error">
      <template v-if="error?.statusCode === 404">
        <div class="error-box">
          <img src="https://static.ppkao.com/www/images/404.png" alt="页面未找到" title="Page not found"/>
          <span>看起来您迷路了。返回首页，继续探索！</span>
        </div>
        <NuxtLink to="/" v-wave v-if="error?.statusCode === 404">回到首页</NuxtLink>
      </template>
      <template v-else-if="/timeout/ig.test(error?.message)">
        <div class="error-box">
          <img src="https://static.ppkao.com/www/images/timeout.png" style="width: 200px" alt="网络链接超时" :title="error?.message"/>
          <span>请求超时, 请刷新重试。</span>
        </div>
        <el-button :loading="loading" @click="reload" v-wave>刷新页面</el-button>
      </template>
      <template v-else-if="error?.statusCode === 502">
        <div class="error-box">
          <OopsSvg />
          <span style="margin-top: 0">{{ error.statusMessage.replace(/https?:\/\/[^"]+/, '') || '服务器无响应，请刷新页面试试吧。' }}</span>
        </div>
      </template>

      <template v-else-if="error?.statusCode === 500">
        <div class="error-box">
          <img src="https://static.ppkao.com/www/images/500.png" alt="服务器错误" :title="error?.message" />
          <span >{{ error.message }}</span>
        </div>

        <el-button :loading="loading" @click="reload" v-wave>重新加载</el-button>
      </template>
      <template v-else>
        <div class="error-box">
          <img src="https://static.ppkao.com/www/images/error.png" alt="未知错误" title="Internal server error"/>
          <span>哎呀，似乎出了点问题。请耐心等待，我们会尽快解决。</span>
        </div>
        <el-button :loading="loading" @click="reload" v-wave>重新加载</el-button>
      </template>

    </div>
    <NuxtLayout name="footer"></NuxtLayout>
  </div>
</template>

<script setup>
const OopsSvg = defineAsyncComponent(() => import('@/assets/svgs/oops.svg'));
const loading = ref(false);
const error = useError();
const route = useRoute();
console.error('[error details]:', error);
const reload = () => {

  loading.value = true;
  clearError({ redirect: route.path })
    .finally(() => loading.value = false);
  location.reload();

}
</script>

<style scoped lang="scss">
.error-mask{
  width: 100%;
  .error {
    border-radius: 14px;
    margin: 30px auto;
    width: var(--error-width);
    height: 580px;
    align-items: center;
    justify-content: center;
    @include flex-col-center;
    background-color: var(--card-bg-color);
    &-box {
      @include flex-col-center;
      @include font(var(--primary-text-color), 16px, 600);
      span {
        margin-top: 60px;
        font-size: 16px;
      }
    }
    a,button {
      margin-top: 22px;
      @include btn(110px, 42px, linear-gradient(180deg, #0379FF, #76B4FF), 8px, 16px, #fff);
    }
}
}
</style>
