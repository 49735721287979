export default defineNuxtPlugin((nuxtApp) => {
  // 从 localStorage 中读取暗夜模式状态
  const getInitialDarkMode = () => {
    if (process.client) {
      const savedMode = localStorage.getItem('isDarkMode');
      return savedMode ? JSON.parse(savedMode) : false;
    }
    return false;
  };
  
  const isDarkMode = useState('isDarkMode', getInitialDarkMode);
 
  const toggleDarkMode = () => {
    isDarkMode.value = !isDarkMode.value;
    if (process.client) {
      localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode.value));
      sendMessageIframe({isDarkMode: isDarkMode.value});
    }
  };
  
  const toggleTheme = (event) => {
    const x = event.clientX;
    const y = event.clientY;
    const endRadius = Math.hypot(Math.max(x, innerWidth - x), Math.max(y, innerHeight - y));
    
    if (!document.startViewTransition) {
      toggleDarkMode();
      return;
    }
    const transition = document.startViewTransition(async () => {
      toggleDarkMode();
    });
    
    transition.ready.then(() => {
      const clipPath = [
        `circle(0px at ${x}px ${y}px)`,
        `circle(${endRadius}px at ${x}px ${y}px)`,
      ];
      document.documentElement.animate(
        { clipPath: isDarkMode.value ? [...clipPath].reverse() : clipPath },
        { duration: 400, easing: "ease-in", pseudoElement: isDarkMode.value ? "::view-transition-old(root)" : "::view-transition-new(root)" }
      );
    });
  }
  // 仅在客户端环境中执行
  if (process.client) {
    isDarkMode.value = getInitialDarkMode();
    // 初始加载时根据状态设置类名
    document.documentElement.classList.toggle('dark', isDarkMode.value);
    
    watchEffect(() => {
      document.documentElement.classList.toggle('dark', isDarkMode.value);
    });

  }
  
  return {
    provide: {
      darkMode: {
        isDarkMode,
        toggleTheme,
      },
    },
  };
});
